import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import scrollTo from 'gatsby-plugin-smoothscroll';
export default function Sectwo(){
    return (
        <>
        
             <Container fluid className="__bg-secondary-sl">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__image_ipad-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BMockup%5D-CM.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                            <div className="d-flex justify-content-center wl-am-fr mt-3">
                                
                                    <Button variant="primary" size="lg" onClick={() => scrollTo('#buy-up')}>
                                        YA SAYA MAU DAFTAR
                                    </Button>{' '}
                               
                               
                            </div>
                                <p className="text-center pt-3 __text-link" onClick={() => scrollTo('#buy-up')}><u>( klik disini untuk instan akses )</u></p>
                        </Col>
                    </Row>
                    <Row className="fade-in __margin-top-sl-100">
                        <Col lg={6} className="order-lg-last order-md-last">
                            <div className="__wrapper-img-headline-sl">
                                <img
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5Bsec1%5D-girl.png"
                                    alt="image cm"
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="__wrappe-headline-sl">
                                <h2>Sadarkah Kamu</h2>
                                <p>
                                    Banyak wanita frustasi mengapa calon pasangan mereka tidak kunjung bertindak, bertekuk lutut sambil membuka kotak berisi cincin dan berkata...<br/>
                                    “Will you marry me, honey…?”
                                </p>
                                <p>
                                    Ahh.. nyatanya, itu cuma angan-angan yang entah kapan bisa terwujud!
                                </p>
                                <p>
                                    Sementara hubunganmu dengannya sudah mulai diambang “kebosanan”,
                                    Kamu masih belum juga menemukan cara bagaimana <b>MENGGIRING DIA KE ARAH LEBIH SERIUS.</b>
                                </p>
                                <p>
                                    Dan tanpa Kamu sadari, kamu sendiri sebenarnya masih tidak paham..
                                </p>
                                <p>
                                    <b>Bagaimana pria memandang sebuah komitmen,</b>
                                </p>
                                <p>
                                    Apa yang membuatnya menunda sementara kalian sudah cukup akrab,
                                </p>
                                <p>
                                    Bagaimana kamu bisa <b>menanam ide komitmen TANPA TERLIHAT ngebet nikah,</b>
                                </p>
                                <p>
                                    Dan <b>apakah dia pria yang tepat</b> untuk kamu ajak <b>berpetualang menjalani pernikahan seumur hidup?</b>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__bg-white-rounded-sl __margin-top-sl-50">
                                <Row className="fade-in">
                                    <Col lg={12}>    
                                        <div className="__wrappe-headline-sl">
                                            <h2>Apakah Kamu sedang mengalami hal ini?</h2>
                                        </div>
                                    </Col>
                               
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Pacaran bertahun-tahun tapi belum ada arah yang jelas menuju pernikahan
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Ingin cepat dilamar 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Mau tahu dia komit sama kamu atau tidak 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Merasa ragu dia pria yang tepat atau tidak untuk diajak komit
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Ingin pria yang disuka komit sama kamu
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Bingung bagaimana bicara tentang komitmen tanpa si pria merasa “ditagih”
                                            </p>
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex">
                                            <div className="__width-icon-and-text">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>                               
                                            </div>
                                            <p className="__text-desc-icon-sl">
                                                Penasaran apa yang membuat pria “suka menunda” dan bagaimana cara menginspirasi mereka untuk segera bertindak
                                            </p>
                                        </div>
                                    </Col>
                                   
                                </Row> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
           

        </>
    )
}

